export default function showVideo() {
    (function($) {
        /**
         *  otwieranie wideo z YT w modalu
         */
        
        //Odtawrzacz YT
        var tag = document.createElement('script')
        tag.src = "https://www.youtube.com/iframe_api"
        var firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        var player

        $(document).on('click', '.js-show-video', function(){
            var video_id = $(this).attr('data-video-id')

            if (typeof video_id == 'string') {
                //start filmu
                processPlayer('js-overlay-video-container', video_id)
            }
        }); 

        function processPlayer(container, video_id) {
            player = new YT.Player(container, {
                videoId: video_id,
                playerVars: {rel: 0},
                events: {
                    'onReady' : onPlayerReady
                }
            });

            function onPlayerReady(event) {
                player.playVideo();
            };
        }

        
        $('body').on('overlay:off-video', function() {
            player.stopVideo()
            player.destroy()
        })    
    })(window.jQuery);
}