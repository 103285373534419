export default function green() {
    (function($) {

            const boxes = document.querySelectorAll('.green__box');
            let activeDescription = null;

            boxes.forEach(box => {
                box.addEventListener('click', event => {
                    console.log('klkl');
                    const description = box.querySelector('.green__description');
                    if (activeDescription && activeDescription !== description) {
                        activeDescription.classList.remove('-active');
                    }
                    
                    description.classList.toggle('-active');
                    activeDescription = description.classList.contains('-active') ? description : null;
                    
                    event.stopPropagation();
                });
            });

            document.addEventListener('click', () => {
                if (activeDescription) {
                    activeDescription.classList.remove('-active');
                    activeDescription = null;
                }
            });

    })(window.jQuery);
}