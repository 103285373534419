const {slick} = require("slick-carousel");
export default function productSlider() {
    (function($) {
        // Product slider

        var slider = $('#product-slider'), //slider
            sliderNav = '.js-product-slider-nav', // slider nav
            prev = null, // Prev btn
            next = null; // Next btn

        // Slick
        slider.slick({
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            nextArrow: '<button type="button" class="slick-next icon-next">Next</button>',
            prevArrow: '<button type="button" class="slick-prev icon-next">Previous</button>'
        });

        // slider nav
        $(sliderNav).on('click', function() {
            slider.slick('slickGoTo', $(this).attr('data-target'))
        })

        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $(sliderNav).each(function(){
                $(this).removeClass('active-slide')
            })
            $(sliderNav + '[data-target="' + nextSlide + '"]').addClass('active-slide')
        });

        // init simpleLightBox
        if( $('.slider__item').length ) {
            var lightbox = $('.slider__item').not('.slick-cloned').simpleLightbox({
                showCounter: false,
                docClose: false,
                navText: [
                    '<button type="button" class="simplelightbox-prev icon-prev"></button>',
                    '<button type="button" class="simplelightbox-next icon-next"></button>'
                ],
                overlay: false,
                className: 'icon-logo'
            });
        }

        $('.slider__zoom').on('click', function() {
            lightbox.open();
        })

        $('.slider__item').on('show.simplelightbox', function () {
            // console.log("przed odpaleniem");
        });

        $('.slider__item').on('shown.simplelightbox', function () {
            // console.log("po odpaleniu");
            $(".sl-wrapper").addClass("sl-wrapper--visible");
        });

        $('.slider__item').on('close.simplelightbox', function () {
            // console.log("przed zamknieciem");
            $(".sl-wrapper").removeClass("sl-wrapper--visible");
        });

        $('body').on('click', '.sl-image img', function () {
            $(this).trigger('dblclick')
            $(this).addClass('is-zoomed')
        });
    })(window.jQuery);
}