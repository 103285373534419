import {Collapse, Modal, Tooltip} from "bootstrap";
const {selectric} = require("selectric");
const {default: accordion} = require("./scripts/accordion");
const {default: awardsSlider} = require("./scripts/awards-slider");
const {default: colorSlider} = require("./scripts/color-slider");
const {default: esg} = require("./scripts/esg");
const {default: green} = require("./scripts/green");
const {default: filters} = require("./scripts/filters");
const {default: gifAutoplay} = require("./scripts/gif-autoplay");
const {default: gridFilters} = require("./scripts/grid-filters");
const {default: header} = require("./scripts/header");
const {default: historySlider} = require("./scripts/history-slider");
const {default: homeSlider} = require("./scripts/home-slider");
const {default: homeVideosAutoplay} = require("./scripts/home-videos-autoplay");
const {default: lang} = require("./scripts/lang");
const {default: notepad} = require("./scripts/notepad-js");
const {default: offset} = require("./scripts/offset");
const {default: overlay} = require("./scripts/overlay");
const {default: pinModal} = require("./scripts/pin-modal");
const {default: productSlider} = require("./scripts/product-slider");
const {default: reloadClients} = require("./scripts/reload-clients");
const {default: renderCertificate} = require("./scripts/render-certificate");
const {default: saveCard} = require("./scripts/save-card");
const {default: scrollbar} = require("./scripts/scrollbar");
const {default: showVideo} = require("./scripts/show-video");
const {default: smoothScrolling} = require("./scripts/smooth-scrolling");
const {default: tooltips} = require("./scripts/tooltips");
const {default: topMenu} = require("./scripts/top-menu");
const {default: productsSlider} = require("./scripts/products-slider");
const {default: player} = require("./scripts/player");

var documentReady = function () {
    accordion();
    awardsSlider();
    colorSlider();
    esg();
    green();
    filters();
    gifAutoplay();
    gridFilters();
    header();
    historySlider();
    homeSlider();
    homeVideosAutoplay();
    lang();
    notepad();
    offset();
    overlay();
    pinModal();
    productSlider();
    reloadClients();
    renderCertificate();
    saveCard();
    scrollbar();
    showVideo();
    smoothScrolling();
    tooltips();
    topMenu();
    productsSlider();
    player();
};

if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
    documentReady()
    filters()
} 
else {
    document.addEventListener("DOMContentLoaded", documentReady)
}


(function ($) {
    

    /**
     * Inicjalizacja podstawowych
     */
    // window.onload = function () {
        // AOS.init({
            // once: true,
            // disable: 'mobile'
        // });
    // } 

    // Magnific popup
    // $(".popup-with-zoom-anim a, .video__play").magnificPopup({
	// 	type: 'inline',

	// 	fixedContentPos: true,
	// 	fixedBgPos: true,

	// 	overflowY: 'auto',

	// 	closeBtnInside: false,
    //     preloader: false,
    //     tClose: 'Zamknąć',
		
	// 	midClick: true,
	// 	removalDelay: 300,
    //     mainClass: 'my-mfp-zoom-in',
    // });

    // // Btn close popup
    // $(".video__close").on( "click", function(e) {
    //     e.preventDefault();
    //     $.magnificPopup.close();
    // });

    // Selectric
    $('.select').selectric({
        arrowButtonMarkup: '<span class="icon-chevron"></span>',
    });
    $('.select').on('selectric-open', function(event, element, selectric) {
        $('.input-label').addClass('input-label--active');
    });
    $('.select').on('selectric-close', function(event, element, selectric) {
        $('.input-label').removeClass('input-label--active');
    });

    // Search focus
    $('.header__search-icon').click(function(){
        setTimeout(function () {
            $('#searchInput').focus()
        }, 100);
    });

})(jQuery);