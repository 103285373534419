export default function pinModal() {
    (function($) {
        var $showPin = '.js-show-pin-modal',
            $hidePin = '.js-close-pin-modal',
            $pinModal = '.js-pin-modal'

        $($showPin).on('click', function(e) {
            e.preventDefault()
            var target = $(this).attr('data-target')
            $($pinModal).each(function() {
                $(this).removeClass('active')
            })
            $(target).addClass('active')
        })

        $('body').on('click', $hidePin, function(e) {
            e.preventDefault()
            $($pinModal).each(function() {
                $(this).removeClass('active')
            })
        })
    })(window.jQuery);
}