const {selectric} = require("selectric");
export default function gridFilters() {
    (function($) {

        const productsFiltersBox = document.querySelector(".products-filters");

        /**
         * clear radio
         */
        $(document).on('click', '.js-clear-radio', function(e) {
            e.preventDefault()
            var target = $(this).attr('for')

            if ($('#' + target).is(':not(:disabled)')) {
                if ($('#' + target).is(':checked')) {
                    $('#' + target).prop('checked', false).trigger('change')
                }
                else {
                    $('#' + target).prop('checked', true).trigger('change')
                }
            }
        })

        /**
         * clear input
         */
        $(document).on('click', '.js-clear-input', function(e) {
            e.preventDefault()
            var target = $(this).attr('for')
            $('#' + target).val('').trigger('change')
        })

        /**
         * clear select
         */
        $(document).on('click', '.js-clear-select', function(e) {
            e.preventDefault()
            var target = $(this).attr('for')
            $('#' + target).val('').trigger('change')
            $('#' + target).selectric('refresh');
        })

        /**
         * clear inputs
         */
        $(document).on('click', '.js-clear-inputs', function(e) {
            e.preventDefault()
            var target = $(this).attr('for')
            target = target.split(',')
            target.forEach(function(elem) {
                var input = $('[name="' + elem + '"]')
                if (input.length > 0) {
                    input.val('')
                    input.trigger('change')
                }
            })
        })

        /**
         * show clear inputs
         */
        $('.js-show-clear-button').find('input').change(function() {
            var parents = $(this).parents('.js-show-clear-button')
            var active = false
            parents.find('input').each(function() {
                if ($(this).val().length > 0) {
                    active = true
                }
            })
            parents.find('select').each(function() {
                if ($(this).val().length > 0) {
                    active = true
                }
            })
            if (active) {
                parents.addClass('show-clear')
            }
            else {
                parents.removeClass('show-clear')
            }
        })

        /**
         * show clear select
         */
        $('.js-show-clear-button').find('select').change(function() {
            var parents = $(this).parents('.js-show-clear-button')
            var active = false
            parents.find('select').each(function() {
                if ($(this).val().length > 0) {
                    active = true
                }
            })
            if (active) {
                parents.addClass('show-clear')
            }
            else {
                parents.removeClass('show-clear')
            }
        })
        
        /**
         * show clear inputs
         */
        $('.js-show-clear-button').find('input').change(function() {
            var parents = $(this).parents('.js-show-clear-button')
            var active = false
            parents.find('input').each(function() {
                if ($(this).val().length > 0) {
                    active = true
                }
            })
            if (active) {
                parents.addClass('show-clear')
            }
            else {
                parents.removeClass('show-clear')
            }
        })

        /**
         * reload on submit
         */
        $('#js-filter-results').click(function() {
            getSearchFilters();
        })

        $(".products__search-form").on("submit", (e) => {
            e.preventDefault();
            $('#js-filter-results').trigger("click");
        })

        $(".products__search-form .products__search-icon").on("click", (e) => {
            e.preventDefault();
            $('#js-filter-results').trigger("click");
        })
        
        /**
         * pagination
         */
        $('.pagelimit').click(function(e) {
            e.preventDefault();
            $('.pagelimit').removeClass('active');
            $(this).addClass('active');
            console.log('click 12');
            getSearchFilters();
        });

        /**
         * reload additional filters
         */
        $('.js-category-id').change(function() {
            const val = $('[name="category_id"]:checked').val();
            ajaxReload(val, 'regular');
        })
        
        $(document).ready(() => {
            const val = $('[name="category_id"]:checked').val();
            if (val) ajaxReload(val, 'regular');
        })

        $('[data-cat-name]').on("change", () => {
            $('.filters__sub input').prop( "checked", false );
        })

        const handleInputToCheck = (inputs, ids, isReset = false) => {
            if (isReset) {
                inputs.forEach(input => {
                    input.parentElement.classList.remove("block");
                })
            } else if (ids != -1) {
                inputs.forEach(input => {
                    const wpTermId = parseInt(input.getAttribute("data-filter-id"));
                    
                    if (wpTermId) {
                        if (!ids.includes(wpTermId)) {
                            input.parentElement.classList.add("block");
                            input.checked = false;
                        }
                    }
                })
            }
        }

        function ajaxReload(cat, mode) {
            var container = $('#js-additional-filters')
            var page_url = window.location.search.substring(1)
            var variables = page_url.split('&')
            var variables_list = {}
            const filters = $(".filters__list");

            if (filters.length === 0) return;

            const inputsToCheck = document.querySelectorAll(".js-check-filter");
            $('.overlay--filters').addClass('busy');
            handleInputToCheck(inputsToCheck, false, true);
            
            for (var i = 0; i < variables.length; i++) {
                var param = variables[i].split('=')
                variables_list[param[0]] = param[1]
            }
            
            if(cat) variables_list['category_id'] = cat     
            
            $.ajax({
                url: ajax.url,
                data: {
                    action: 'reload_new_filters',
                    value: variables_list,
                    mode: mode
                },
                type: 'post',
                success: function (data) {
                    data = JSON.parse(data);
                    
                    const {terms_ids} = data;
                    
                    if (inputsToCheck && terms_ids) {
                        handleInputToCheck(inputsToCheck, terms_ids, false);
                    }
                    $('.overlay--filters').removeClass('busy');
                }
            });

            return false
        }

        function getSearchFilters(){
        
            if($(".grid.filters input[name=client]").length > 0)
                var client_id = $(".grid.filters input[name=client]").val();

            var category_id = $(".grid.filters input[name=category_id]:checked").val();
            var category_name = $(".grid.filters input[name=category_id]:checked").data('cat-name');
            var installation_id = $(".grid.filters input[name=installation_id]:checked").val();
            var shape_id = $(".grid.filters input[name=shape_id]:checked").val();
            var structure_id = $(".grid.filters input[name=structure_id]:checked").val();
            var surface_id = $(".grid.filters input[name=surface_id]:checked").val();
            var evermite = $(".grid.filters input[name=evermite]:checked").val();
            var available_in_matt = $(".grid.filters input[name=available_in_matt]:checked").val();
            var exclusivity = $(".grid.filters input[name=exclusivity]:checked").val();
            var overflow_id = $(".grid.filters input[name=overflow_id]:checked").val();
            var bowl_position_id = $(".grid.filters input[name=bowl_position_id]:checked").val();
            var is_new_product = $(".grid.filters input[name=is_new_product]:checked").val();
            var dolomite_cover = $(".grid.filters input[name=dolomite_cover]:checked").val();
            var family_id = $(".grid.filters input[name=family_id]").val();
            var pagelimit = $('.pagelimit.active').data('pagelimit');
            // var color = $(".grid.filters select[name=color]").val();
            //var url = WEBROOT+"/"+PRODUCTS+"/?";
            var url = $('input[name="page-url"]').val() + '?';
            var url_parts = [];
            var show_subs = true;
            var is_bath = true;
            var languageUrlCount = $('input[name="products-url"]').data('language-url-count');
            console.log(languageUrlCount);

            const searchPhraseInput = $(".products__search-form .products__search-input").val();

            if ( parseInt( category_id ) > 0 ) {
                var pathArray = window.location.pathname.split('/');

                if (parseInt(languageUrlCount) > 3){
                    pathArray[2] = 'products';
                }

                var newPathname = "";

                var hasCategory = pathArray.includes("category");

                var cat_id_index = '',
                    cat_name_index = '';
                
                var i_cat_name_index = 5;

                if (hasCategory == true) {
                    cat_name_index = parseInt(languageUrlCount);
                    i_cat_name_index = parseInt(languageUrlCount);
                    
                } else { 
                    pathArray.push('category', '', '');

                    cat_id_index = (pathArray.length == 6) ? 6 : 5;
                    cat_name_index = (pathArray.length == 6) ? 7 : 6;
                }

                for (var i = 0; i < pathArray.length; i++) {

                    if (i == i_cat_name_index) {
                        pathArray[cat_name_index] = category_name.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '');
                    }
                }

                pathArray =  [...new Set(pathArray)];
                newPathname += pathArray.join('/');
                url = newPathname;
                url = url.slice(0, url.length) + '?';
                
                url_parts.push( "cat_id=" + category_id );
                if ( category_id == 3 || category_id == 1 ) {
                    is_bath = true;
                }
            } else {
                // url = $(".grid.filters input[name=products-url]").val() + "?";
                show_subs = false;
            }

            // url = url.slice(0, url.length - 1) + '?';

            if (parseInt(installation_id) > 0 && show_subs){
                url_parts.push( "installation_id=" + installation_id );
            }

            if (parseInt(shape_id) > 0){
                url_parts.push( "shape_id=" + shape_id );
            }
            if (parseInt(overflow_id) > 0){
                url_parts.push( "overflow_id=" + overflow_id );
            }
            if (parseInt(is_new_product) >= 0){
                url_parts.push( "is_new_product=" + is_new_product );
            }
            if (parseInt(dolomite_cover) >= 0){
                url_parts.push( "dolomite_cover=" + dolomite_cover );
            }
            if (parseInt(family_id) >= 0){
                url_parts.push( "family_id=" + family_id );
            }
            if (parseInt(bowl_position_id) > 0){
                url_parts.push( "bowl_position_id=" + bowl_position_id );
            }
            if (parseInt(structure_id) > 0 ){
                url_parts.push( "structure_id=" + structure_id );
            }
            if (parseInt(surface_id) > 0 ){
                url_parts.push( "surface_id=" + surface_id );
            }
            if (parseInt(evermite) > -1 ){
                url_parts.push( "evermite=" + evermite );
            }
            if (parseInt(available_in_matt) > -1 ){
                url_parts.push( "available_in_matt=" + available_in_matt );
            }
            if (parseInt(exclusivity) > -1 ){
                url_parts.push( "exclusivity=" + exclusivity );
            }
            if (parseInt(pagelimit) > 0 ){
                url_parts.push( "pagelimit=" + pagelimit );
            }
            if (parseInt(client_id) > 0){
                url_parts.push( "client=" + client_id );
            }

            $('.grid.filters input[type="text"]').each(function() {
                var $this = $(this);
                if ($this.val().length > 0){
                    url_parts.push( $this.attr('name') + "=" + $this.val() );
                }
            });

            $('.grid.filters input[type="checkbox"]:checked').each(function() {
                var $this = $(this);
                if (!url_parts.includes($this.attr('name') + "=" + $this.val())){
                    url_parts.push( $this.attr('name') + "=" + $this.val() );
                }

            });

            $('.grid.filters select').each(function() {
                var $this = $(this);
                if (parseInt($this.val()) > -1){
                    url_parts.push( $this.attr('name') + "=" + $this.val() );
                }
            });

            if (searchPhraseInput && searchPhraseInput.length > 1) {
                if (!url_parts.includes('phrase' + "=" + searchPhraseInput)){
                    url_parts.push( 'phrase' + "=" + searchPhraseInput );
                }
            }

            url+= url_parts.join('&');
            if (url_parts.length === 0) url.replace("?", "");

            window.location.href = url;
        }
    })(window.jQuery);
}