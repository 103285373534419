export default function esg() {
    (function($) {
        var list = $('.js-show-list-elem')
        $(window).scroll(function () {
            list.each(function() {
                var item = $(this)
                if (item.isInViewport()) {
                    if (!item.hasClass('is-active')) {
                        item.addClass('is-active')
                    }
                }
            })
        })

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top
            var elementBottom = elementTop + $(this).outerHeight()
        
            var viewportTop = $(window).scrollTop()
            var viewportBottom = viewportTop + ($(window).height() / 1.5)
        
            return elementBottom > viewportTop && elementTop < viewportBottom
        };
    })(window.jQuery);
}