export default function offset() {
    (function($) {
        // Offset
        $(function () {
            $(window).resize(function () {
                clearTimeout(window.resizedFinished);
                window.resizedFinished = setTimeout(function(){
                    setSliderPosition();
                }, 250);
            });

            function setSliderPosition() {

                // Vars
                var eLeft = $('.container').offset().left - 20; // Offset left
                var eWidth = $('.js-sidebar-container').width(); // Width

                $('.sidebar-wrapp').css('right', -eLeft).css('width', eWidth + eLeft + 15);
            }

            setSliderPosition();
        });
    })(window.jQuery);
}