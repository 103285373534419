export default function notepad() {
    (function($) {
        /**
         * clear notepad
         */
        var clearButton = '.js-clear-notepad',
        notepadCounter = '.js-notepad-counter',
        notepadSpan = '.js-notepad-span > span',
        widgetContent = '.js-cart-widget-content',
        widgetControler = '.js-cart-widget-control',
        addToNotepad = '.js-add-to-notepad',
        deleteFromNotepad = '.js-delete-from-notepad',
        switchNotepad = '.js-switch-notepad',
        deleteNotepad = '.js-delete-notepad-trigger',
        deleteNotepadForm = '#js-delete-notepad',
        saveNotepad = '#js-save-notepad',
        $container = $('#js-loading-container')

        $(document).ready(function() {
            $(widgetContent).addClass('is-loaded');
            $(widgetControler).addClass('is-loaded');
            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'get_notepad'
                },
                success: function (response) {
                    $(notepadCounter).html(response);
                    $(notepadSpan).html(response);
                    $(widgetControler).removeClass('is-loaded')
                    $(widgetContent).removeClass('is-loaded');
                },
                error: function (response) {
                    console.log(response)
                    $(widgetContent).removeClass('is-loaded');
                    $(widgetControler).removeClass('is-loaded')
                }
            });

            return false;

        })

        /**
         * wyczyść notatnik
         */
        $('body').on('click', clearButton, function(e) {
            e.preventDefault()
            $(widgetContent).addClass('is-loaded');
            $(".notepad-bar").addClass("active");
            
            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'clear_notepad'
                },
                success: function (response) {
                    $(notepadCounter).html(response);
                    $(notepadSpan).html(response);
                    $(widgetContent).removeClass('is-loaded');
                    $(".notepad-bar").remove();
                    $(".notepad-gap").remove();
                    $(".notepad-bar").removeClass("active");

                    if ($(".js-delete-from-notepad").length > 0) {
                        $(".js-delete-from-notepad").trigger("click");
                    }
                },
                error: function (response) {
                    console.log(response)
                    $(widgetContent).removeClass('is-loaded');
                }
            });

            return false;
        })

        /**
         * dodaj do notatnika
         */

        $('body').on('click', addToNotepad, function(e){
            e.preventDefault()
            let $that = $(this)
            $(widgetControler).addClass('is-loaded')
            $that.addClass('is-loaded')

            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'add_to_notepad',
                    product_id: $(this).attr('data-product-id')
                },
                success: function (response) {
                    $(notepadCounter).html(response);
                    $(notepadSpan).html(response);
                    $(widgetControler).removeClass('is-loaded')
                    $that.addClass('is-hidden')
                },
                error: function (response) {
                    console.log(response)
                    $(widgetControler).removeClass('is-loaded')
                    $that.removeClass('is-loaded')
                }
            });

            return false;
        });

        /**
         * zmień notatnik
         */
        $('body').on('click', switchNotepad, function(e){
            e.preventDefault()
            var $that = $(this)
            $container.addClass('is-loaded')

            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'switch_notepad',
                    notepad_id: $that.attr('data-notepad-id')
                },
                success: function (response) {
                    $(notepadCounter).html(response)
                    $(notepadSpan).html(response)
                    window.location.href = $that.attr('data-redirect-url')
                },
                error: function (response) {
                    console.log(response)
                    $container.removeClass('is-loaded')
                }
            });

            return false;
        });

        /**
         * usuń z notatnika
         */
        $('body').on('click', deleteFromNotepad, function(e){
            e.preventDefault()
            let $that = $(this)
            $that.addClass('is-loaded')
            $that.parents('.js-notepad-box').remove()

            const productIdToRemove = $(this).attr('data-product-id');
            const notepadBarItemToRemove = $(`[data-notepad-box-id=${productIdToRemove}]`);
            $(".notepad-bar").addClass("active");

            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'delete_from_notepad',
                    product_id: $(this).attr('data-product-id')
                },
                success: function (response) {
                    $(notepadCounter).html(response)
                    $(notepadSpan).html(response);

                    notepadBarItemToRemove.remove();
                    console.log($('.notepad-bar__product-box'));
                    if ($('.notepad-bar__product-box').length === 0) {
                        $('.notepad-bar').remove();
                        $(".notepad-gap").remove();
                    }
                    $(".notepad-bar").removeClass("active");

                    // generowanie katalogu
                    $that.parents('.js-notepad-box').remove()
                    var export_array = []
                    $('.js-notepad-box').each(function() {
                        var product_id = $(this).attr('data-product-id')
                        export_array.push(product_id)
                    })
                    export_list =  export_array.join(',')
                    $('[name="products"]').val(export_list)
                    $('.js-ppt-export-list').attr('data-product-id', export_list)
                    $('.js-generate-ppt').attr('data-product-id', export_list)
                    $('.js-generate-xml').attr('data-products', export_list);

                    
                },
                error: function (response) {
                    console.log(response)
                    $that.removeClass('is-loaded')
                }
            });

            return false;
        });

        /**
         * usuń zapisany notatnik
         */
        var currentNotepad = false;

        $('body').on('click', deleteNotepad, function(e){
            e.preventDefault()
            currentNotepad = $(this).attr('data-notepad-id')
        });

        $(deleteNotepadForm).on('submit', function(e){
            e.preventDefault()

            let $that = $(this)
            $that.addClass('is-loaded')

            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'delete_notepad',
                    notepad: currentNotepad
                },
                success: function (response) {
                    window.location.href = $that.find('[name="redirect_url"]').val()
                },
                error: function (response) {
                    console.log(response)
                    $that.removeClass('is-loaded')
                }
            });

            return false;
        });

        /**
         * zapisz notatnik
         */
        $(saveNotepad).on('submit', function(e){
            e.preventDefault()

            let $that = $(this)
            $that.addClass('is-loaded')

            $.ajax({
                type: 'post',
                url: ajax.url,
                data: {
                    action: 'notepad_change',
                    operation: 'save_notepad',
                    name: $that.find('[name="name"]').val()
                },
                success: function (response) {
                    $(notepadCounter).html(response)
                    $(notepadSpan).html(response)
                    window.location.href = $that.find('[name="redirect_url"]').val()
                },
                error: function (response) {
                    console.log(response)
                    $that.removeClass('is-loaded')
                }
            });

            return false;
        });

        $('.js-show-attrs').change(function(){
            var checkbox = $(this),
                toShow = checkbox.data('show-attr'),
                divToShow = $('.notepad-generate div[data-attr="' + toShow + '"]');

            divToShow.toggleClass('hidden');

            if($('input', divToShow).prop('disabled'))
                $('input', divToShow).prop('disabled', false);
            else
                $('input', divToShow).prop('disabled', true);
        });

        $("#js-save-notepad input.title").keyup(function() {
            if($(this).val().length > 0)
                $('#js-save-notepad input.save').prop('disabled', false);
            else
                $('#js-save-notepad input.save').prop('disabled', true);
        });
    })(window.jQuery);
}