export default function accordion() {
    (function($) {
        $('.collapse').on('show.bs.collapse', function () {

            $(this).parent().addClass('is-collapsed');

        }).on('hide.bs.collapse', function () {

            $(this).parent().removeClass('is-collapsed');

        });
    })(window.jQuery);
}