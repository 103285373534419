const {slick} = require("slick-carousel");
export default function awardsSlider() {
    (function($) {
        var slider = '#js-awards-slider',
            sliderDots = $('#js-awards-slider-dots'),
            descriptionSlider = '#js-awards-description-slider'

        $(slider).slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            centerMode: true,
            centerPadding: '0px',
            appendDots: sliderDots,
            asNavFor: descriptionSlider,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $(descriptionSlider).slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            asNavFor: slider
        });
    })(window.jQuery);
}