export default function overlay() {
    (function($) {
        var overlay = $(".overlay"),
            overlayOpen = $(".js-overlay-open"),
            overlayClose = $(".js-overlay-close"),
            body = $('body'),
            hamburger = $('.hamburger'),
            scrollbarWidth = (window.innerWidth - document.getElementsByTagName('html')[0].clientWidth),
            source = ''

        $('body').on('click', '.js-overlay-open', function(e) {
            e.preventDefault()
            // e.stopPropagation()
            source = $(this).attr('data-source');

            if( !overlayOpen.hasClass("js-overlay-close") ) {
                $(".overlay--" + source).addClass("overlay--visible");
                body.addClass('no-scroll').css('padding-right', scrollbarWidth);
                hamburger.removeClass('js-overlay-open');
                hamburger.addClass("hamburger-icon--open js-overlay-close");
                $(".header").css("right", scrollbarWidth).addClass('header--overlay-open');

                if ($('.overlay--navigation').hasClass('overlay--visible')) {
                    $('#js-top-lang-menu').addClass('active')
                }

            } else {
                body.removeClass('no-scroll').css('padding-right', 0);
                overlay.removeClass("overlay--visible");
                hamburger.removeClass("hamburger-icon--open js-overlay-close");
                hamburger.addClass('js-overlay-open');
                $(".header").css("right", 0).removeClass('header--overlay-open');
                $('#js-top-lang-menu').removeClass('active')
            }

            $('body').trigger('overlay:on-' + source);
        });

        $('body').on('click', '.js-overlay-close', function() {
            body.removeClass('no-scroll').css('padding-right', 0);
            overlay.removeClass("overlay--visible");
            hamburger.removeClass("hamburger-icon--open js-overlay-close");
            hamburger.addClass('js-overlay-open');
            $(".header").css("right", 0).removeClass('header--overlay-open');
            $('#js-top-lang-menu').removeClass('active')

            $('body').trigger('overlay:off-' + source);
        });

        $('body').on('click', '.js-multiple-overlay-open', function(e) {
            e.preventDefault();
            overlay.removeClass("overlay--visible");
            source = $(this).attr('data-source');
            setTimeout(function() {
                $(".overlay--" + source).addClass("overlay--visible");
            }, 250)
        });
    })(window.jQuery);
}