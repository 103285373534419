export default function reloadClients() {
    (function($) {
        $('.js-mda-submit-on-change').on('change', function () {

            var popupGenerate = $('div.popup#generate-notepad');
            popupGenerate.fadeIn(250);
            $('.page-content').addClass('busy');
    
            $(this).closest($('form')).submit();
        });
    })(window.jQuery);
}