const {slick} = require("slick-carousel");
export default function colorSlider() {
    (function($) {
        let $colorSlider = $('#js-color-slider')

        var trigger = '.js-color-version-trigger',
            item = '.js-color-version'

        $('.js-color-slider').click(function() {
            var slideIndex = $(this).attr('data-slide-index');
            $(trigger).removeClass('is-main');
            $('img[data-color-id="' + slideIndex + '"]').addClass('is-main');
        
            $colorSlider.slick('slickGoTo', parseInt(slideIndex));
        });

        // Slick
        $colorSlider.slick({
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            fade: true,
            nextArrow: '<button type="button" class="slick-next icon-next">Next</button>',
            prevArrow: '<button type="button" class="slick-prev icon-next">Previous</button>'
        });

        $colorSlider.on('afterChange', function() {
            $(item).removeClass('is-visible')
        })

        $('body').on('click', trigger, function(){
            $(item).removeClass('is-visible')
            var target = $(this).attr('data-target')
            $(target).addClass('is-visible')
        })
    })(window.jQuery);
}