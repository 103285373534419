export default function player() {

    if($('#video')){
        $('#video').on('click', function(){
            video.controls = true;
        });
    }

    const greenCover = document.querySelector('.green__cover');
    if (greenCover) {
        greenCover.addEventListener('click', function() {
            this.style.display = 'none';

            const iframe = document.querySelector('.green__iframe');
            if (iframe) {
                const src = iframe.src;
                iframe.src = src + "&autoplay=1&mute=1";
            }
        });
    }

    (window.jQuery);
}
