const {slick} = require("slick-carousel");
export default function homeSlider() {
    (function($) {
        // Product slider
        var slider = $('#home-showroom-slider')

        // Slick
        slider.slick({
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            nextArrow: '<button type="button" class="slick-next icon-next">Next</button>',
            prevArrow: '<button type="button" class="slick-prev icon-next">Previous</button>',
            appendDots: $('#home-showroom-slider-dots')
        });

    })(window.jQuery);
}