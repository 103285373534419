const {slick} = require("slick-carousel");
export default function productsSlider() {
    (function($) {
        var slider = '#js-products-slider',
            sliderDots = $('#js-products-slider-dots')

        $(slider).slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            appendDots: sliderDots,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    })(window.jQuery);
}