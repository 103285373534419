export default function smoothScrolling() {
    (function($) {
        $(document).ready(function () {
            $(".smooth-scroll").on("click", function (event) {
                event.preventDefault();
                var id  = $(this).attr('href'),
                    top = $(id).offset().top;
                $('body,html').animate({scrollTop: top - 100}, 300);
            });
        });
    })(window.jQuery);
}