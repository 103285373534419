export default function topMenu() {
    (function($) {
        $('.js-top-menu-toggle').on('click', function () {
            $menu = $('.js-top-menu');
            if ($menu.hasClass('show')) {
                $menu.removeClass('show');
                $(this).removeClass('show');
            } else { 
                $menu.addClass('show');
                $(this).addClass('show');
            }
        });
    })(window.jQuery);
}