export default function renderCertificate() {
   (function($) {
      let $rowClass = '.js-table-download'

      $($rowClass).hover(function() {
         $item = $(this)
         if ($item.length > 0) {
            var left = $item.offset().left + $item.innerWidth()
            var top = $item.offset().top + ($item.innerHeight() / 2)
            console.log('trigger');

            $('#js-certificate-trigger').css({
               'top' : top,
               'left' : left,
               'opacity' : 1,
               'visibility' : 'visible'
            })
         }
      })

      $($rowClass).mouseleave(function() {
         $('#js-certificate-trigger').css({
            'opacity' : 0,
            'visibility' : 'hidden'
         })
      })

      $('body').on('click', $rowClass, function() {
         var that = $(this)
         var parent = that.parents('table')
         var table = '<h4>' + parent.attr('data-title') + '</h4><table><thead>' + parent.find('thead').html() + '<thead><tbody>' + that.html() + '</tbody></table>'
         var style = '<style type="text/css">* {font-family: sans-serif;}table {border-collapse: collapse;width: 100%;} table th, table td {border: 1px solid #000;padding: 10px 15px;}</style>';
         var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
         winPrint.document.write(table + style);
         winPrint.document.close();
         winPrint.focus();
         winPrint.print();
      })
   })(window.jQuery);
}