const {mCustomScrollbar} = require("malihu-custom-scrollbar-plugin");
const {mousewheel} = require("jquery-mousewheel");

export default function scrollbar() {
    (function($) {
        /**
         * scrollbar
         */
        $(".js-custom-scroll").mCustomScrollbar({
            mouseWheel: {
                enable: true
            }
        });
    })(window.jQuery);
}