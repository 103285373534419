export default function saveCard() {
    (function($) {
        var validate = function() {
            if ($('#notepad-description textarea').val().length == 0 || $('#notepad-description input:checked').length == 0)
                $('#notepad-description input.generate').prop('disabled', true);
            else
                $('#notepad-description input.generate').prop('disabled', false);
        }
    
        $( '#notepad-description textarea' ).keyup(function() {
            validate();
        });
    
        $( '#notepad-description input[type="checkbox"]' ).click(function() {
            validate();
        });
    
    
        $('body').on('submit', '#notepad-description', function(e){
            e.preventDefault();
    
            var data = {
                'action': 'mnp_generate_single_PDF',
                'product_id': $('input[name="product-id"]', this).val(),
                'comment': $('textarea[name="comment"]', this).val()
            };
    
            $(":checked", this).each(function() {
                data[$(this).attr('name')] = $(this).val();
            });
    
            $('.overlay--generate-card').addClass('busy');
    
            jQuery.post(ajaxurl, data, function(response) {
                console.log('Got this from the server: ' + response);
    
                downloadAsync(response);
                $('.overlay--generate-card').removeClass('busy');
            });
    
        });
    
        var downloadAsync = function(url) {
            var f = document.createElement('iframe');
            f.style.display = 'none';
            f.className = "iframe-download";
            f.src = url;
            document.body.appendChild(f);
        
            setTimeout(function() {
                $('iframe.iframe-download').remove();
            }, 5000);
        };
    })(window.jQuery);
}