const {slick} = require("slick-carousel");
export default function historySlider() {
    (function($) {
        //main slider
        var slider = $('#js-history-slider')
        var arrowContainer = $('#js-history-slider-arrows')

        slider.slick({
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.js-nav-slider',
            appendArrows: arrowContainer,
            nextArrow: '<button type="button" class="slick-next icon-next">Next</button>',
            prevArrow: '<button type="button" class="slick-prev icon-next">Previous</button>',
            adaptiveHeight: true
        });

        //nav sliders
        var settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            asNavFor: '.js-nav-slider',
            draggable: false,
            swipe: false,
            touchMove: false,
            // responsive: [
            //     {
            //         breakpoint: 1380,
            //         settings: {
            //             slidesToShow: 1,
            //         }
            //     },
            // ]
        }
        
        var sliderNavPrev = $('#js-history-slider-prev')
        sliderNavPrev.slick(settings);
        sliderNavPrev.find('.slick-slide').on('click', function() {
            slider.slick('slickGoTo', $(this).attr('data-slick-target'));
        })
        
        var sliderNavNext = $('#js-history-slider-next')
        sliderNavNext.slick(settings);
        sliderNavNext.find('.slick-slide').on('click', function() {
            slider.slick('slickGoTo', $(this).attr('data-slick-target'));
        })

        //additional nav
        var additionalPrev = $('#js-additional-prev')
        additionalPrev.on('click', function() {
            slider.slick('slickPrev');
        })
        
        var additionalNext = $('#js-additional-next')
        additionalNext.on('click', function() {
            slider.slick('slickNext');
        })

        slider.on('afterChange', function(event, slick, currentSlide) {
            var prev = slider.find('[data-slick-index="' + (currentSlide - 1) + '"]').attr('data-slide-year')
            var next = slider.find('[data-slick-index="' + (currentSlide + 1) + '"]').attr('data-slide-year')
            
            if (prev) {
                additionalPrev.text(prev)
                additionalPrev.addClass('active')
            }
            else {
                additionalPrev.removeClass('active')
            }

            if (next) {
                additionalNext.text(next)
                additionalNext.addClass('active')
            }
            else {
                additionalNext.removeClass('active')
            }
            
            var top = slider.offset().top;
            $('body, html').animate({scrollTop: top - 200}, 300);

        });
    })(window.jQuery);
}