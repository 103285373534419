export default function header() {
    (function($) {
        $(window).scroll(function(){
            if( $(this).scrollTop() >= 30 ) {
                $(".header").addClass('header--scroll header--white');
                $("body").addClass('scrolled');
            } else  {
                $(".header").removeClass('header--scroll  header--white');
                $("body").removeClass('scrolled');
            }
        });
    
        $(document).ready(function(){
            if( $(this).scrollTop() >= 30 ) {
                $(".header").addClass('header--scroll header--white');
                $("body").addClass('scrolled');
            } else  {
                $(".header").removeClass('header--scroll  header--white');
                $("body").removeClass('scrolled');
            }
        });
    })(window.jQuery);
}