export default function homeVideosAutoplay() {
   (function($) {
      var $container = $('.js-advantage-player-container'),
         playerClass = '.js-advantage-player'
     
       $container.on('mouseover', function() {
          const player = $(this).find(playerClass);
          if (player.length > 0) {
             player.trigger('play');
          }
       })

       $container.on('mouseout', function() {
          const player = $(this).find(playerClass);
          if (player.length > 0) {
             player.trigger('pause');
          }
       })
   })(window.jQuery);
}