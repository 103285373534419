export default function gifAutoplay() {
    (function($) {
        var container = $('#js-maintenance-gif'),
            player = $('#js-maintenance-player'),
            ended = false

        player.bind('ended', function() {
            ended = true
        });
        
        $(window).scroll(function() {
            if (ended != true && container.length > 0) {
                var top_of_element = container.offset().top + (container.outerHeight() / 2);
                var bottom_of_element = container.offset().top + (container.outerHeight() / 4);
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                    player.trigger('play');
                }
                else {
                    player.trigger('pause');
                }
            }
        });
    })(window.jQuery);
}